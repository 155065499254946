@tailwind base;

@font-face {
  font-family: NotoSansKR;
  font-weight: 300;
  font-display: swap;
  src: local('NotoSansKR'), url('/assets/fonts/NotoSansKR-Light.woff2') format('woff2'),
    url('/assets/fonts/NotoSansKR-Light.woff') format('woff');
}

@font-face {
  font-family: NotoSansKR;
  font-weight: 400;
  font-display: swap;
  src: local('NotoSansKR'), url('/assets/fonts/NotoSansKR-Regular.woff2') format('woff2'),
    url('/assets/fonts/NotoSansKR-Regular.woff') format('woff');
}

@font-face {
  font-family: NotoSansKR;
  font-weight: 500;
  font-display: swap;
  src: local('NotoSansKR'), url('/assets/fonts/NotoSansKR-Medium.woff2') format('woff2'),
    url('/assets/fonts/NotoSansKR-Medium.woff') format('woff');
}

@font-face {
  font-family: NotoSansKR;
  font-weight: 700;
  font-display: swap;
  src: local('NotoSansKR'), url('/assets/fonts/NotoSansKR-Bold.woff2') format('woff2'),
    url('/assets/fonts/NotoSansKR-Bold.woff') format('woff');
}

html {
  width: 100%;
  height: 100%;
}

body {
  width: 100%;
  height: 100%;
  line-height: 1.6;
}

@tailwind components;

@tailwind utilities;
